const restServer = "https://demo.aixi.co/rest"

/////////////////////////////////////////////////////////

//Delays API, get data for the total minutes delayed, average and sum of minutes per ata for the top N delay ATAs
export async function delaysAPI(token,topN,logout) {
  let url = `${restServer}/delays`
  url+= "?topN="+topN.toString()
  return await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token,
        //'Usertype' : userType,
        //Authorization: `Bearer ${authorization}`
      }
    }).then(res => res.json()).catch(function(error) {
       logout(error.toString());
    }).then(res => { 
       if(Object.keys(res).indexOf('error') >= 0){
          logout(res['error'])
       }
       else
         return res
    });
}


//Autocoder text API, given a problem_description + " " + resolution_description for a log issue, return all the model codes for it
export async function autocoderAPI(token,data,logout,model=null,thresh=null) {
  let url = `${restServer}/autocoder`
  if(model){
     url+= "?model="+model
     if(thresh)
        url+= "&thresh="+thresh.toString()
  }
  else if (thresh)
     url+= "?thresh="+thresh.toString()
  return await fetch(url, {
      body: JSON.stringify(data),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token' : token,
        //'Usertype' : userType,
        //Authorization: `Bearer ${authorization}`
      }
    }).then(res => res.json()).catch(function(error) {
       logout(error.toString());
    }).then(res => { 
       if(Object.keys(res).indexOf('error') >= 0){
          logout(res['error'])
       }
       else
         return res
    });


}

//Translate Note API, given a mechanic note, return a description in easy to understand english
export async function translateNoteAPI(token,note,logout) {
  return await fetch(`${restServer}/translate_note?note=${note}`, {
      method: 'GET',
      headers: {
        'x-access-token' : token,
        'Content-Type': 'application/json',
        //'Usertype' : userType,
        //Authorization: `Bearer ${authorization}`
      }
    }).then(res => res.json()).catch(function(error) {
       logout(error.toString());
    }).then(res => { 
       if(Object.keys(res).indexOf('error') >= 0){
          logout(res['error'])
       }
       else
         return res
    });


}

//Trends Data API input: start_date and end_date (format "YYYY-MM-DD") returns: the number of occurrences of each value for the given category, the mean, st deviation, and zscore WRT to the entire dataset aggregated into similar periods. optional: a pandas selection string
export async function deviationDataAPI(token,startDate,endDate,cat,logout,top_n=5,select="",ataSelect="") {
  console.log('deviationAPI',startDate,endDate,cat,top_n,select,ataSelect,token)
  if(!(select == ""))
     select = btoa(select)
  if(!(ataSelect == ""))
     ataSelect = btoa(ataSelect)
  return await fetch(`${restServer}/deviation_data?start_date=${startDate}&end_date=${endDate}&cat=${cat}&top_n=${top_n}&select=${select}&ata_select=${ataSelect}`, {
      method: 'GET',
      headers: {
        'x-access-token' : token,
        'Content-Type': 'application/json',
        //'Usertype' : userType,
        //Authorization: `Bearer ${authorization}`
      }
    })
      .then(res => res.json()).catch(function(error) {
       logout(error.toString());
    }).then(res => { 
       if(Object.keys(res).indexOf('error') >= 0){
          logout(res['error'])
       }
       else
         return res
    });
}

//Repeat Failures Data API input: start_date and end_date (format "YYYY-MM-DD") returns: an array of arrays. each subarray contains information about a particular fact_ata code that is "out of bounds" in terms of how many times it is seen vs the average over similar time periods. the information is: ata,count,mean,stdev,z-score,all the dates,all the discrepancies, all the facts.
export async function repeatFailuresAPI(token,startDate,endDate,logout) {
  return await fetch(`${restServer}/repeat_failures?start_date=${startDate}&end_date=${endDate}`, {
      method: 'GET',
      headers: {
        'x-access-token' : token,
        'Content-Type': 'application/json',
        //'Usertype' : userType,
        //Authorization: `Bearer ${authorization}`
      }
    })
      .then(res => res.json()).catch(function(error) {
       logout(error.toString());
    }).then(res => { 
       if(Object.keys(res).indexOf('error') >= 0){
          logout(res['error'])
       }
       else
         return res
    });
}


//Historical Data API input: aggDays: the number of days you would like to aggregate over, column type and column value. returns: 2 arrays.  The first has the time values of middle of the same historical time values and the second has the count/sum of the desired column+values over the same past historical time periods.
export async function historicalDataAPI(token,aggDays,cat,val,logout) {
      
  return await fetch(`${restServer}/historical_data?agg_days=${aggDays}&cat=${cat}&val=${val}`, {
      method: 'GET',
      headers: {
        'x-access-token' : token,
        'Content-Type': 'application/json',
        //'Usertype' : userType,
        //Authorization: `Bearer ${authorization}`
      }
    })
      .then(res => res.json()).catch(function(error) {
       logout(error.toString());
    }).then(res => { 
       if(Object.keys(res).indexOf('error') >= 0){
          logout(res['error'])
       }
       else
         return res
    });
}

//get logs api - given a selection statement, return the log data (date opened, acn, fault description, fault resolution
export async function getLogAPI(token,select,logout) {
  if(!(select == ""))
     select = btoa(select)
  return await fetch(`${restServer}/get_logs?select=${select}`, {
      method: 'GET',
      headers: {
        'x-access-token' : token,
        'Content-Type': 'application/json',
        //'Usertype' : userType,
        //Authorization: `Bearer ${authorization}`
      }
    })
      .then(res => res.json()).catch(function(error) {
       logout(error.toString());
    }).then(res => { 
       if(Object.keys(res).indexOf('error') >= 0){
          logout(res['error'])
       }
       else
         return res
    });
}

//get a list of files uploaded by current user
export async function fileListAPI(token,logout) {
  return await fetch(`${restServer}/file_list`, {
      method: 'GET',
      headers: {
        'x-access-token' : token,
        'Content-Type': 'application/json',
        //'Usertype' : userType,
        //Authorization: `Bearer ${authorization}`
      }
    })
      .then(res => res.json()).catch(function(error) {
       logout(error.toString());
    }).then(res => { 
       if(Object.keys(res).indexOf('error') >= 0){
          logout(res['error'])
       }
       else
         return res
    });
}

//change the file that is used in the application
export async function fileChangeAPI(token,filename,logout) {
  filename = btoa(filename)
  return await fetch(`${restServer}/change_file?filename=${filename}`, {
      method: 'POST',
      headers: {
        'x-access-token' : token,
        'Content-Type': 'application/json',
        //'Usertype' : userType,
        //Authorization: `Bearer ${authorization}`
      }
    })
      .then(res => res.json()).catch(function(error) {
       logout(error.toString());
    }).then(res => { 
       if(Object.keys(res).indexOf('error') >= 0){
          logout(res['error'])
       }
       else
         return res
    });
}


//get "final fixes" and their historical probabilities for a given fault ATA
export async function finalFixAPI(token,ata,logout) {
  return await fetch(`${restServer}/final_fixes?ata=${ata}`, {
      method: 'GET',
      headers: {
        'x-access-token' : token,
        'Content-Type': 'application/json',
        //'Usertype' : userType,
        //Authorization: `Bearer ${authorization}`
      }
    })
      .then(res => res.json()).catch(function(error) {
       logout(error.toString());
    }).then(res => { 
       if(Object.keys(res).indexOf('error') >= 0){
          logout(res['error'])
       }
       else
         return res
    });
}

//login a user, if successful the userInfo object will be returned with username and access token
export async function loginAPI(username,password,logout) {
  return await fetch(`${restServer}/login`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'u' : btoa(username),
        'p' : btoa(password),
      }
    })
      .then(res => res.json()).catch(function(error) {
       logout(error.toString());
    }).then(res => { 
       if(Object.keys(res).indexOf('error') >= 0){
          logout(res['error'])
       }
       else
         return res
    });
}


