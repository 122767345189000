import React from 'react';
import {autocoderAPI, getLogAPI, deviationDataAPI} from './APIHelpers'
import { Table, ProgressBar } from 'react-bootstrap'
import Plot from 'react-plotly.js';
import loading from './loading.gif'
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';


class TrendsView extends React.Component {
  constructor(props) {
    super(props);
    let defaultTopN = 10;
    const defaultColors = new Array(defaultTopN).fill("#1f76b4")
    this.state = {initial:true,data: [], startDate:'2022-04-01', endDate:'2022-11-01',cursor:'default',resultStart:'2022-04-01',resultEnd:'2022-11-01',colors:{object_code:defaultColors.slice(),corrective_action_ata:defaultColors.slice(),action_code:defaultColors.slice(),failure_mode:defaultColors.slice()},select:[],dateErrorString:"",logData:[],showLogs:false,dateRadio:'custom',top_n:defaultTopN,submitted:false,lookupInfo:"",ataList:""};

  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  try_quote = (val) => {
    if (Number(val))
      return Number(val.toString()).toString()
    else
      return '"'+val.toString()+'"'
  }

  ataSelect = () => {
    let result = ""
    if(this.state.ataList.length > 3){
       result = this.state.ataList.replaceAll(","," ")
       result = result.replace(/\s\s+/g, ' ');
       result = "ata == " + result.split(" ").join(" or ata == ")
    }
    return result;
  }


  getNextTitle = (currTitle) => {
    const ix = this.props.title_order.indexOf(currTitle)
    if(ix >= 0)
       return this.props.title_order[ix+1]
    return null
  }

  pickedBarValue(plotName,plotNum,barIx,barVal) {
     const defaultColor = "#1f76b4"
     const activeColor = "#9e0000"
     const currColors = this.state.colors
     const defaultColors = new Array(this.state.top_n).fill(defaultColor)

     const nextTitle = this.getNextTitle(plotName)
     if(nextTitle) {
        if(currColors[plotName][barIx] == defaultColor)
          currColors[plotName][barIx] = activeColor;
        else 
          currColors[plotName][barIx] = defaultColor;
   
        for(let i=0;i<currColors[plotName].length;i++)
           if (!(barIx == i))
             currColors[plotName][i] = defaultColor;
    
        const currData = this.state.data.slice(0,plotNum+1)
        const currSelect = this.state.select.slice(0,plotNum)
        currSelect.push(plotName+" == "+ this.try_quote(barVal))
        currColors[nextTitle] = defaultColors.slice();
        this.setState({colors:currColors,cursor:'progress'})
 
        deviationDataAPI(this.props.user.token,this.state.startDate,this.state.endDate,nextTitle,this.props.logout,this.state.top_n,currSelect.join(" and "),this.ataSelect()).then(res=>{
           if(res){
              currData.push({title: nextTitle,devs: res,picked: null});
              this.setState({data : currData, select : currSelect, cursor:'default'});
           }
        })    
    }
    if(nextTitle == 'acn' && this.props.title_order.indexOf('acn')==4){
        let currSelect = this.state.select.slice(0,plotNum)
        currSelect.push(plotName+" == "+ this.try_quote(barVal))
        currSelect = currSelect.join(" and ")
        getLogAPI(this.props.user.token,currSelect).then(res=>{
          if(res)
            this.setState({logData:res});
        })

    }  
    else if(!(plotName == 'acn'))
      this.setState({logData:[]}) 
  }


  differenceInDays = (startDate,endDate) =>{
   let date1 = new Date(startDate)
   let date2 = new Date(endDate)
   return (date2.getTime() - date1.getTime())/(1000 * 3600 * 24)
   }


   makeTitle = (title) => {
    let words = title.replaceAll("aixi_pred_","").replaceAll("_"," ").replaceAll("ata","ATA").replace('fact','Corrective Action').replaceAll('acn','ACN')
                     .split(" ")
                     .map(word => {return word[0].toUpperCase()+word.substring(1,word.length)})
                     .join(" ");
    return words
  } 

   undoFormatting = (text) => {
    let words = text.replaceAll("ACN: ","").replaceAll("ATA: ","").trim()
    return words
  } 

  logTable = () => {
   if(this.state.logData.length > 0)
    return (<div> <div style={{paddingBottom:'20px'}}> <center> <h3> Log Entries: </h3> </center> </div> <Table striped bordered hover>
      <thead>
        <tr>
          <th>Date Opened</th>
          <th>ACN</th>
          <th>Fault Description</th>
          <th>Fault Resolution</th>
        </tr>
      </thead>
      <tbody>
     { this.state.logData[0].map((item,j) => (
        <tr>
          <td>{this.state.logData[0][j]}</td>
          <td>{this.state.logData[1][j]}</td>
          <td>{this.state.logData[2][j]}</td>
          <td>{this.state.logData[3][j]}</td>
        </tr>
      ))}
      </tbody>
    </Table> </div>)
   }

  lookupClicked = () => {
     this.setState({'cursor':'progress'})
     autocoderAPI(this.props.user.token,this.state.lookupInfo,this.props.logout,"ata",0.005).then(res=>{
       if(res){
          let ataList = res.map(item=>item[0])
          this.setState({'cursor':'default',ataList:ataList.join(", ")})
       }
     })
  }

  plotAllData = () => {
     return this.state.data.map((data,i)=>(
        <Plot
           key = {`barplot-${data.title}`}
           data = {[
           { 
             name: ' ',
             type: 'bar',
             x: data['devs'].map(item=>item[1]).reverse(),
             y: data['devs'].map(item=>item[0]).reverse(),
             orientation: 'h',
             showlegend: false,
             marker:{size:16, color:this.state.colors[data['title']]}
           },
           { 
             name: 'Avg',
             type: 'scatter',
             mode: 'markers',
             marker : {color: 'red'},
             x: data['devs'].map(item=>item[2]).reverse(),
             y: data['devs'].map(item=>item[0]).reverse(),
             error_x: {
                type: 'data',
                array: data['devs'].map(item=>item[3]).reverse(),
               visible: true
             },
             orientation: 'h',
             showlegend: false,
           },
          ]}
          onClick= {(pdata) => {
              let val = this.undoFormatting(pdata.points[0].fullData.y[pdata.points[0].pointIndex]);
              let aggDays = this.differenceInDays(this.state.startDate,this.state.endDate)
              this.pickedBarValue(data['title'],i,pdata.points[0].pointIndex,val)
          }}

           config={ {displayModeBar: false} }
           layout={ {width: 400, height: 340, title: this.makeTitle(data['title']), yaxis : {automargin:true}} }
        />))

     }
 

   countChar = (text,charValue) => {
     return(text.split(charValue).length - 1)
   }

   topNChanged = (e) => {
     this.setState({top_n:parseInt(e.target.value)})
   }

   dateChanged = (name,value) => {

    if(name == 'startDate')
       this.setState({startDate:value,dateRadio:'custom'});
    if(name == 'endDate')
       this.setState({endDate:value,dateRadio:'custom'});

   }
  

   subtractMonths = (date, months) => {
      var copiedDate = new Date(date.getTime());
      copiedDate.setUTCMonth(date.getUTCMonth() - months);
      return copiedDate;
   }

  dateRadioClicked = (evt) => {
 
    const val = evt.target.value
    if (!(val == "custom")){

       let endDate = new Date("2022-11-01")
       let startDate = new Date(this.state.startDate)
   
       if(val == "oneMonth")
          startDate = this.subtractMonths(endDate,1)
       else if (val == "sixMonths")
          startDate = this.subtractMonths(endDate,6)
       else if (val == "oneYear")
          startDate = this.subtractMonths(endDate,12)
   
       
       endDate = `${endDate.getUTCFullYear()}-${endDate.getUTCMonth()+1}-${endDate.getUTCDate()}`
       startDate = `${startDate.getUTCFullYear()}-${startDate.getUTCMonth()+1}-${startDate.getUTCDate()}`
       this.setState({dateRadio:val,endDate:endDate,startDate:startDate})
  }
    else
       this.setState({dateRadio:val})

 }

 
  submitClicked = (evt) => {
   let errStr = ""
   if(!Date.parse(this.state.startDate))
      errStr += 'Invalid Start Date (YYYY-MM-DD) '
   if (!Date.parse(this.state.endDate))
      errStr += 'Invalid End Date (YYYY-MM-DD) '
   
   if(!errStr == "")
      this.setState({dateErrorString: errStr})
   else{ 
     const defaultColor = "#1f76b4"
     const defaultColors = new Array(this.state.top_n).fill(defaultColor)
     const currColors = this.state.colors
     currColors[this.props.title_order[0]] = defaultColors.slice()

      this.setState({cursor:'progress',initial:false,colors:currColors,logData:[]});
   deviationDataAPI(this.props.user.token,this.state.startDate,this.state.endDate,this.props.title_order[0],this.props.logout,this.state.top_n,"",this.ataSelect()).then(res=> {
      if(res)
         this.setState({data : [{title: this.props.title_order[0],devs: res,picked: null}],cursor:'default',resultStart:this.state.startDate, resultEnd:this.state.endDate,dateErrorString:"",submitted:true})
    })
   }
  }
  render() {
    return (
         <div style={{cursor: this.state['cursor']}}>

           <center> <h1> {this.props.title} </h1> </center>
           <div style={{fontSize:'1.04em',paddingTop:'20px'}}>
            {"Date Range:"} <br/> {"Start:  "} 
             <input size="10"  value={this.state.startDate} onChange={evt => this.dateChanged('startDate',evt.target.value)} placeholder="YYYY-MM-DD" pattern="[0-9]{4}-[0-9][0-9]?-[0-9][0-9]?"/>
             {"   End:  "} 
             <input size="10" placeholder="YYYY-MM-DD" value={this.state.endDate} onChange={evt => this.dateChanged('endDate',evt.target.value)} pattern="[0-9]{4}-[0-9][0-9]?-[0-9][0-9]?"/>
             {"    "}
             <div style={{padding:'6px'}}>
                Last: {"  "}
                <input  
                   checked={this.state.dateRadio=="oneMonth"} 
                   value="oneMonth" 
		   onClick={e=>this.dateRadioClicked(e)} 
		   type="radio"
                /> 
		Month {"  "}

                <input 
		   checked={this.state.dateRadio=="sixMonths"} 
 		   type="radio"
                   value="sixMonths" 
		   onClick={e=>this.dateRadioClicked(e)} 
		   type="radio"

		/>
	        6 Months {"  "}
                <input 
		   checked={this.state.dateRadio=="oneYear"} 
 		   type="radio"
                   value="oneYear" 
		   onClick={e=>this.dateRadioClicked(e)} 
		   type="radio"

		/>

                Year {"  "}
                <input 
		   checked={this.state.dateRadio=="custom"} 
 		   type="radio"
                   value="custom" 
		   onClick={e=>this.dateRadioClicked(e)} 
		   type="radio"
		/>
                Custom {"  "}
             </div>
                  Top N: <input value={this.state.top_n} size={3} onChange={e=>this.topNChanged(e)}/> 
             <div style={{width:'35%',paddingBottom:'10px',paddingTop:'10px'}}>
            <FloatingLabel
              controlId="floatingInput"
              label="ATA Codes"
              className="mb-3"
            >
           <Form.Control type="text" placeholder="" value={this.state.ataList} onChange={e=>this.setState({ataList:e.target.value})} />
            </FloatingLabel>
               <FloatingLabel controlId="floatingTextarea" label="Lookup ATAs From Descripton">
               <Form.Control type="floatingTextArea" placeholder="" value={this.state.lookupInfo} onChange={e=>this.setState({lookupInfo:e.target.value})}/>
             </FloatingLabel> 
                  <div style={{width:'fit-content',fontSize:'15px',padding:'3px',marginTop:'6px',marginLeft:'auto',marginRight:'10px'}}> 
                     <input value="Lookup" type="button" onClick={e=>this.lookupClicked()}/>
                   </div>
                 <br/> 
                
             </div>
             <input type="submit" value="Submit" onClick={evt=>this.submitClicked(evt)}/>
            <div style={{color:'red'}}> <center> {this.state.dateErrorString} </center> </div>
           <div>{this.state.cursor == 'progress' && (<img height='80px' width='120px' src={loading}/>)}</div>

            <div style={{fontSize:'1.3em',paddingLeft:'30px',marginTop:'40px',textAlign:'left'}}>
              {this.state.submitted && "Trends for " + this.state.resultStart+' to '+this.state.resultEnd +":"}
            </div>
          </div>
          <br/> <br/>
          <div style={{display:'flex',flexType:'row',width:'100%', flexWrap: "wrap"}}>
             {this.plotAllData()}
          </div>
            <div style={{width:'80%'}}>
             {this.logTable()}
            </div>
         </div>
    );
  }
}


export default TrendsView;

