import React from 'react';
import loading from './loading.gif'
import {delaysAPI} from './APIHelpers'
import { Table, ProgressBar } from 'react-bootstrap'
import Plot from 'react-plotly.js';
import Form from 'react-bootstrap/Form';


class DelaysView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {cursor:'progress',delayData:null};

  }

  componentDidMount() {
   
     delaysAPI(this.props.user.token,20,this.props.logout).then(res=>{
       if(res){
          console.log(res)
          this.setState({cursor:'default',delayData:res})
       }
     })
  }

  componentWillUnmount() {
  }
 
  getColor = (i) => {
    let colors = ['red', 'cyan', 'darkorange', 'magenta', 'rosybrown', 'lavender', 'lavenderblush', 'linen', 'mistyrose', 'mediumpurple', 'firebrick', 'tomato', 'lightpink', 'fuchsia', 'cornsilk', 'crimson', 'indianred', 'orchid', 'saddlebrown', 'gray', 'lightgrey', 'lightyellow', 'lightsalmon', 'navajowhite', 'pink', 'mediumblue', 'azure', 'limegreen', 'powderblue', 'darkblue', 'darkgray', 'green', 'whitesmoke', 'blueviolet', 'darkred', 'snow', 'lightgoldenrodyellow', 'cadetblue', 'sienna', 'seashell', 'yellow', 'darkcyan', 'aquamarine', 'gold', 'steelblue', 'honeydew', 'lemonchiffon', 'ghostwhite', 'mediumturquoise', 'cornflowerblue', 'lightskyblue', 'mediumspringgreen', 'forestgreen', 'floralwhite', 'hotpink', 'silver', 'lime', 'orangered', 'aliceblue', 'tan', 'oldlace', 'chartreuse', 'lightblue', 'lightcyan', 'lightseagreen', 'blanchedalmond', 'darkslategray', 'midnightblue', 'ivory', 'seagreen', 'coral', 'lawngreen', 'lightslategray', 'darkorchid', 'mediumorchid', 'dodgerblue', 'mediumslateblue', 'olivedrab', 'peachpuff', 'plum', 'lightsteelblue', 'gainsboro', 'royalblue', 'darkmagenta', 'indigo', 'peru', 'mediumseagreen', 'slateblue', 'darkgreen', 'purple', 'bisque', 'mintcream', 'thistle', 'moccasin', 'violet', 'beige', 'lightgreen', 'burlywood', 'deeppink', 'wheat', 'skyblue', 'springgreen', 'salmon', 'aqua', 'papayawhip', 'mediumvioletred', 'darkturquoise', 'palegreen', 'darkslateblue', 'lightcoral', 'darkgoldenrod', 'darkkhaki', 'yellowgreen', 'darkviolet', 'darkseagreen', 'darksalmon', 'chocolate', 'teal', 'palegoldenrod', 'paleturquoise', 'brown', 'sandybrown', 'antiquewhite', 'deepskyblue', 'orange', 'mediumaquamarine', 'greenyellow', 'slategray', 'navy', 'darkolivegreen', 'maroon', 'turquoise', 'blue', 'palevioletred', 'olive', 'goldenrod', 'black', 'khaki', 'dimgray']
    return colors[i];
  }

  render() {
    return (
     <div>
      {this.state.delayData ? ( <div>
      <Plot 
        data={[
          {
           x: this.state.delayData['months'],
           y: this.state.delayData.total,
           type: 'lines+markers'
          }
        ]}
        layout={ { title: 'Delay Minutes By Month'} }
       config={ {displayModeBar: false} }
      />

      <Plot
        data={Object.keys(this.state.delayData['sum']).map((ata,i) => {
         return {
            x: this.state.delayData['months'],
            y: this.state.delayData['sum'][ata],
            name: ata,
            type: 'scatter',
            mode: 'lines+markers',
            marker: {color: this.getColor(i)},
          }})}
        
        layout={ { height: '700', width: '1000', title: 'Total Delay Minutes By Month Per ATA'} }
        config={ {displayModeBar: false} }
      />
      <Plot
        data={Object.keys(this.state.delayData['avg']).map((ata,i) => {
         return {
            x: this.state.delayData['months'],
            y: this.state.delayData['avg'][ata],
            name: ata,
            type: 'scatter',
            mode: 'lines+markers',
            marker: {color: this.getColor(i)},
          }})}
        
        layout={ { height: '700', width: '1000', title: 'Avg Delay Minutes By Month Per ATA'} }
        config={ {displayModeBar: false} }
      />

     </div>) : (
        <div>{this.state.cursor == 'progress' && (<img height='80px' width='120px' src={loading}/>)}</div>)}
    </div>
    );
  }
}

export default DelaysView;

