import React from 'react';
import {finalFixAPI, repeatFailuresAPI} from './APIHelpers'
import { Button, Table, ProgressBar } from 'react-bootstrap'
import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';
import loading from './loading.gif'
import ConstructionIcon from '@mui/icons-material/Construction';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Modal from 'react-bootstrap/Modal';

class RepeatFailures extends React.Component {
  constructor(props) {
    super(props);
    this.state = {initial:true,allData:null, startDate:'2022-04-01', endDate:'2022-11-01',cursor:'default',resultStart:'',resultEnd:'',showRecords:[],dateErrorString:"",dateRadio:'custom',tooltipText:'Click To Get Final Fixes',ffpath:"",ffdata:null};

  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

   makeTitle = (title) => {
    let words = title.replaceAll("aixi_","").replaceAll("_"," ").replaceAll("ata","ATA").replaceAll('acn','ACN')
                     .split(" ")
                     .map(word => {return word[0].toUpperCase()+word.substring(1,word.length)})
                     .join(" ");
    return words
  } 


   incrementCounter = (counter,key,value) =>
   {
      counter[key] ? counter[key]+=value : counter[key]=value
      return counter
   }


   checkMatch = (prefix,item) => {
      prefix = prefix.split('-_-')
      item = item.split('-_-')
      for (let i=0; i < prefix.length; i++)
        if (prefix[i] != item[i])
          return false
      return true
   }

   getPathTitle = () => {
      let names = ['Fix ATA','Object','Action']
      let spl = this.state.ffpath.split('-_-')
      spl = spl.slice(1,spl.length).map((item,i)=> names[i]+': '+item)
      return spl.join('  >  ')
   }

   getCurrentName = () => {
      let i = this.state.ffpath.split('-_-').length-1
      let names = ['Fix ATA','Object','Action']
      return names[i]
   }

   groupFFData = () => {
     let tresults = {}

     let cLength = this.state.ffpath.split('-_-').length
     let currPath = this.state.ffpath.split('-_-')
     currPath = currPath.slice(1,currPath.length).join('-_-')

     for(let i=0; i < this.state.ffdata.length; i+=1) {
        let displayID = this.state.ffdata[i][0].split('-_-')[cLength-1]
        let matchID = this.state.ffdata[i][0].split('-_-').slice(0,cLength-1).join('-_-')
        if(this.checkMatch(currPath,matchID))
          tresults = this.incrementCounter(tresults,displayID,this.state.ffdata[i][1])
     }

     let sortable = [];
     for (var key in tresults) 
        sortable.push([key, tresults[key]]);

     sortable.sort(function(a, b) {
       return b[1] - a[1];
     });

     return sortable;
   }

   ffItemClicked = (e) => {
     if(this.state.ffpath.split('-_-').length < 3){ 
        let newprefix = ""
        if (this.state.ffpath == ""){
           newprefix = e.target.innerText
        }
        else
           newprefix = this.state.ffpath+'-_-'+e.target.innerText
   
        this.setState({ffpath:newprefix})
     }
   }

   backClicked = () => {
     if(this.state.ffpath.split('-_-').length > 1){
        let newpath = this.state.ffpath.split('-_-')
        newpath = newpath.slice(0,newpath.length-1).join('-_-')
        this.setState({ffpath:newpath})
     }
     else
       this.handleModalClose()  
   }


   showRecords = (i) => {
    
   return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Date Opened</th>
          <th>ACN</th>
          <th>Fault Description</th>
          <th>Fault Resolution</th>
        </tr>
      </thead>
      <tbody>
     { this.state.allData[i][5].map((item,j) => (
        <tr>
          <td>{this.state.allData[i][5][j]}</td>
          <td>{this.state.allData[i][6][j]}</td>
          <td>{this.state.allData[i][7][j]}</td>
          <td>{this.state.allData[i][8][j]}</td>
        </tr>
      ))}
      </tbody>
    </Table>)
   }

   finalFixTable = () =>{
   //ffdata is an array of arrays, looks like this: [[ "2342-_-f/a handset-_-replaced", 0.7192982456140351 ],...]
   if(this.state.ffdata) {
    if (this.state.ffdata.length == 0)
       return ('Not Enough Statistics!')   
    return (
    <div> 
     <div style={{paddingBottom:'5px'}}> 
        <ArrowBackIcon fontSize="small" onClick={e=>this.backClicked()}/>
     </div>
     <div style={{fontSize:'8pt'}}> <b> {this.getPathTitle()}  </b> </div>
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>{this.getCurrentName()}</th>
          <th>Probability</th>
        </tr>
      </thead>
      <tbody>
      </tbody>
      {this.groupFFData().map(item=>{
         return (<tr> <td> <label style={{color:"#324aa8",cursor:'pointer'}} onClick={e=>this.ffItemClicked(e)} > {item[0]} </label> </td> <td> {(item[1]*100).toFixed(2)+"%"}  </td> </tr>)
     })}

    </Table>
    </div>
    ); 
   }
  }
   getFinalFix = (ata) => {
     this.setState({tooltipText:`Getting Final Fixes for ${ata}...`})
     finalFixAPI(this.props.user.token,ata,this.props.logout).then(res=>{
      if(res) 
        this.setState({ffdata:res,ffpath:ata.toString()})
     })
  }

   mouseLeftFF = () => {
     this.setState({tooltipText:`Click To Get Final Fixes`})
   }

   seeRecordClicked = (i) => {
     let currentShows = this.state.showRecords;
     currentShows[i] = currentShows[i] ? null : " "
     this.setState({showRecords: currentShows})
   }

   presentReport = () => {
      return this.state.allData.map((ataData,i)=>(  
        <div style={{padding:'20px'}} key={`dl-${ataData[0]}-${i}`}>
          <Card style={{ width: '18rem' }}>
           <Card.Header> <b> <label id={`label-id-${i}`} onMouseLeave ={e=>this.mouseLeftFF()}  onClick={e=>this.getFinalFix(ataData[0])} style={{color:"#324aa8",cursor:'pointer'}}>  {'Fault ATA:' }{ataData[0]} </label> </b> </Card.Header>
           <ReactTooltip
              anchorId={`label-id-${i}`}
              place="top"
              html = {this.state.tooltipText}
           />
           <ListGroup variant="flush">
             <ListGroup.Item> {"Count: "}  {ataData[1]} </ListGroup.Item>
             <ListGroup.Item>{"Average:"} {ataData[2].toFixed(2)}</ListGroup.Item>
           </ListGroup>
         </Card>        
         {!this.state.showRecords[i] && (<div style={{color:"#324aa8",fontSize:'0.75em'}} onClick={e=>this.seeRecordClicked(i)} > + {'see records'} </div>)}
         {this.state.showRecords[i] && (<div style={{color:"#324aa8",fontSize:'0.75em'}} onClick={e=>this.seeRecordClicked(i)}> - {'hide records'} {this.showRecords(i)}</div>  )}
       </div>
      ))
   }
 


   dateChanged = (name,value) => {

    if(name == 'startDate')
       this.setState({startDate:value,dateRadio:'custom'});
    if(name == 'endDate')
       this.setState({endDate:value,dateRadio:'custom'});
   }

   handleModalClose = () => {
    this.setState({ffdata:null, ffpath:""})
   }
 
   subtractMonths = (date, months) => {
      var copiedDate = new Date(date.getTime());
      copiedDate.setUTCMonth(date.getUTCMonth() - months);
      return copiedDate;
   }

  dateRadioClicked = (evt) => {
 
    const val = evt.target.value
    if (!(val == "custom")){

       let endDate = new Date("2022-11-01")
       let startDate = new Date(this.state.startDate)
   
       if(val == "oneMonth")
          startDate = this.subtractMonths(endDate,1)
       else if (val == "sixMonths")
          startDate = this.subtractMonths(endDate,6)
       else if (val == "oneYear")
          startDate = this.subtractMonths(endDate,12)
   
       
       endDate = `${endDate.getUTCFullYear()}-${endDate.getUTCMonth()+1}-${endDate.getUTCDate()}`
       startDate = `${startDate.getUTCFullYear()}-${startDate.getUTCMonth()+1}-${startDate.getUTCDate()}`
       this.setState({dateRadio:val,endDate:endDate,startDate:startDate})
  }
    else
       this.setState({dateRadio:val})

 }

 
  submitClicked = (evt) => {
   let errStr = ""
   if(!Date.parse(this.state.startDate))
      errStr += 'Invalid Start Date (YYYY-MM-DD) '
   if (!Date.parse(this.state.endDate))
      errStr += 'Invalid End Date (YYYY-MM-DD) '
   
   if(!errStr == "")
      this.setState({dateErrorString: errStr})
   else{ 

      this.setState({cursor:'progress',initial:false});
      repeatFailuresAPI(this.props.user.token,this.state.startDate,this.state.endDate,this.props.logout).then(res=>{
        if(res)
          this.setState({allData : res,cursor:'default',resultStart:this.state.startDate, resultEnd:this.state.endDate, showRecords: new Array(res.length).fill(null) })
       });
   }
  }

  render() {
    return (
  
         <div style={{cursor: this.state['cursor']}}>


        <Modal show={this.state.ffdata} onHide={this.handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title> Final Fixes - Fault ATA: {this.state.ffpath.split('-_-')[0]} </Modal.Title>
        </Modal.Header>
        <Modal.Body> {this.finalFixTable()} </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
           <center> <h1> Repeat Failures </h1> </center>
           <div style={{fontSize:'1.04em',paddingTop:'20px'}}>
            Date Range: <br/> Start:   
             <input size="10"  value={this.state.startDate} onChange={evt => this.dateChanged('startDate',evt.target.value)} placeholder="YYYY-MM-DD" pattern="[0-9]{4}-[0-9][0-9]?-[0-9][0-9]?"/>
             {"   End:  "} 
             <input size="10" placeholder="YYYY-MM-DD" value={this.state.endDate} onChange={evt => this.dateChanged('endDate',evt.target.value)} pattern="[0-9]{4}-[0-9][0-9]?-[0-9][0-9]?"/>
             {"    "}
             <div style={{padding:'6px'}}>
                Last: {"  "}
                <input  
                   checked={this.state.dateRadio=="oneMonth"} 
                   value="oneMonth" 
		   onClick={e=>this.dateRadioClicked(e)} 
		   type="radio"
                /> 
		Month {"  "}

                <input 
		   checked={this.state.dateRadio=="sixMonths"} 
 		   type="radio"
                   value="sixMonths" 
		   onClick={e=>this.dateRadioClicked(e)} 
		   type="radio"

		/>
	        6 Months {"  "}
                <input 
		   checked={this.state.dateRadio=="oneYear"} 
 		   type="radio"
                   value="oneYear" 
		   onClick={e=>this.dateRadioClicked(e)} 
		   type="radio"

		/>

                Year {"  "}
                <input 
		   checked={this.state.dateRadio=="custom"} 
 		   type="radio"
                   value="custom" 
		   onClick={e=>this.dateRadioClicked(e)} 
		   type="radio"
		/>
                Custom {"  "}
             </div>
             <input type="submit" value="Submit" onClick={evt=>this.submitClicked(evt)}/>
            <div style={{color:'red'}}> <center> {this.state.dateErrorString} </center> </div>
           {this.state.allData && ( 
              <div style={{marginTop:'50px',textAlign:'center'}}>
              <div> <h3> Repeat Failure Report </h3> </div> <div><h5> {this.state.resultStart+' to '+this.state.resultEnd} </h5></div>
              </div>
           )}
          </div>
          <br/> <br/>
          {
            this.state.cursor == 'progress' && (<img height='80px' width='120px' src={loading}/>)
          }
          {
            this.state.allData &&  this.presentReport()
          }
         </div>
    );
  }
}


export default RepeatFailures;

