import React from 'react';
import { Button } from 'react-bootstrap'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';

class UserHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {logout:props.logout,user:props.user};
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  render() {
    return (
    <div style={{display:'flex',width:'100%',textAlign:'right'}}>
       <div style={{marginLeft:'auto',fontSize:'16px'}}>
         <PersonOutlineIcon/>
         <b> {this.state.user['name']}</b> <br/>  
         {"  "} <label style={{cursor:'pointer'}} onClick={e=>this.state.logout()}> <u> [Sign Out] </u> </label> 
       </div>
    </div>
    );
  }
}


export default UserHeader;

