import React from 'react';
import {translateNoteAPI,autocoderAPI} from './APIHelpers'
import { Table, ProgressBar } from 'react-bootstrap'
import loading from './loading.gif'

class Classifier extends React.Component {
  constructor(props) {
    super(props);
    this.state = {result:false,cursor:'default',codes:['ata','fact_ata','failure_mode','object_code','action_code']};
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  textChanged = (value) =>{
   this.setState({'text':value});
  }

  outputCodes = () => {
    let results = [<td> <center> Code </center> </td>]
    results = results.concat(Object.keys(this.state['codes']).map(i => { 
	return (
           <td> 
             <center>
		{
		   this.state['result']["pred_" + this.state.codes[i]] ? 
		   this.state['result']["pred_" + this.state.codes[i]] 
	           : "N/A"
		}
	    </center>
	   </td>
        )}))
    return results;
  }

  outputConfidences = () => {
    let results = [<td> <center> Confidence </center> </td>]
    results = results.concat(Object.keys(this.state['codes']).map(i => { 
	return (
           <td> 
             <center>
		{
		   this.state['result']["pred_" + this.state.codes[i]+"_confidence"] ? 
		   (<div style={{width:'130px'}}><ProgressBar 
	              now={this.state['result']['pred_' + this.state.codes[i]+'_confidence']*100} 
		      label={(this.state['result']['pred_' + this.state.codes[i]+'_confidence']*100).toFixed(2)} 
	           /> </div>)
	           : "N/A"
		}
	    </center>
	   </td>
        )}))
    return results;
  }



  showTranslationResults = () => {
   if(this.state['resultTranslation']){
      return(
        <div style={{padding:'25px',border:'1px solid #e6e6e6',borderRadius: '25px',margin:'50px'}}> 
         <h5> <u> Translation: </u> </h5>
         { this.state['resultTranslation']}
        </div>
      )
   }}


  showCodeResults = () => {
   if(this.state['result']){
	 return (
        <div style={{paddingTop:'10px'}}>
          <center> 
             <h3> Result: </h3> 
	     <div style={{width:'80%'}}>
	     <Table striped bordered hover>
               <thead>
                 <tr>
                   <th> </th>
                   <th> <center> DISC ATA </center> </th>
                   <th> <center> FACT ATA </center> </th>
                   <th> <center> FAILURE MODE </center> </th>
                   <th> <center> OBJECT CODE </center> </th>
                   <th> <center> ACTION CODE </center> </th>
                 </tr>
                 </thead>
                 <tbody>
                   <tr>
                      {this.outputCodes()}
                   </tr>
                   <tr>
                      {this.outputConfidences()}
                   </tr>
                 </tbody>
                 </Table>
             </div>
          </center>
        </div> )
   }
   return ""
  }

  handleSubmit = (e)=>  {
   this.setState({cursor:'progress'})
   if (e.target.value == "Get Codes")
      autocoderAPI(this.props.user.token,this.state['text'],this.props.logout).then(res=>{if(res) this.setState({result : res,cursor:'default'})});
   if (e.target.value == "Translate")
      translateNoteAPI(this.props.user.token,this.state['text'],this.props.logout).then(res=>{if(res) this.setState({resultTranslation : res['translation'],cursor:'default'})});
  }

  render() {
    return (
       <div style={{cursor: this.state['cursor']}}>
	<center>
        <h1> 
           Auto Coder
         </h1> <br/>
         <textarea rows = "5" cols = "70" name = "description" placeholder="enter text to code..." onChange={e=>this.textChanged(e.target.value)}>
         </textarea>
         <br/>
         <input type = "submit" value = "Get Codes" onClick={e=>this.handleSubmit(e)} style={{cursor:this.state.cursor}} />  {"    "}
         <input type = "submit" value = "Translate" onClick={e=>this.handleSubmit(e)} style={{cursor:this.state.cursor}} /><br/><br/>
         <div>
          {
            this.state.cursor == 'progress' && (<img height='80px' width='120px' src={loading}/>)
          }

          {this.showCodeResults()}
          {this.showTranslationResults()}
         </div>
	</center>
       </div>
     

    );
  }
}


export default Classifier;

