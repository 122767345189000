import React from 'react';
import { loginAPI } from './APIHelpers'
import { Button } from 'react-bootstrap'
import logo from '../AIXI2.png';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {username:"",password:"",onSuccess:props.onSuccess, errorMsg:props.errorMsg};
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  loginSubmit = (value) =>{
   loginAPI(this.state.username,this.state.password,this.props.logout).then(res=>this.state.onSuccess(res))
  }

  render() {
    return (
       <div style={{paddingTop:'20px'}}>
	<center>
	    <img src={logo} width={230} height={70} className="App-logo" alt="logo"/>  
        <br/><br/><br/>
        <div style={{color:'red'}}>
        {this.props.errorMsg.replaceAll('Signature','Session')}
        </div>
        <div style={{border:'1px solid grey',width:"30%", height:"25%",padding:'40px',borderRadius: "5px"}}>
        <h2> 
           Login
        </h2>
         <br/> 
            <FloatingLabel
              label="Username"
              className="mb-3"
              id="login-usr-label" 
            >
           <Form.Control id='login-usr-input' type="text" placeholder="Username" value={this.state.username} onChange={e=>this.setState({username:e.target.value})} />
            </FloatingLabel>

           <FloatingLabel id='login-pw-label' label="Password">
              <Form.Control id='login-pw-input' type="password" placeholder="Password" value={this.state.password} onChange={e=>this.setState({password:e.target.value})}/>
           </FloatingLabel> 

         <br/>
         <Button variant="primary" onClick={e=>this.loginSubmit()}> Login </Button> 
         </div>
        </center>
       </div>
    );
  }
}


export default Login;

