import React from 'react';
import {fileChangeAPI, fileListAPI} from './APIHelpers'
import { Table, ProgressBar, ListGroup } from 'react-bootstrap'
import loading from './loading.gif'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {cursor:'default', selectedFile:"", fileData:{files:[],current_file:""}};
  }

  componentDidMount() {
   this.getFileList()
  }

  componentWillUnmount() {
  }

  textChanged = (value) =>{
  }

  handleSubmit = (e)=>  {
  }

  getFileList = () => {
      fileListAPI(this.props.user.token,this.props.logout).then(res=>this.setState({fileData:res}))
  }

  itemClicked = (evt) => {
     this.setState({selectedFile : evt.target.innerText})
  }
 
  submitClicked = () => {
     this.setState({cursor:'progress'})
     fileChangeAPI(this.props.user.token,this.state.selectedFile,this.props.logout).then(res=>this.setState({fileData:res,cursor:'default'}))
  }

  getListItem = (filename) => {
     if(filename==this.state.fileData['current_file'])
        return (<ListGroup.Item> {filename} <CheckCircleIcon color="success" fontSize="small"/> </ListGroup.Item>)
     else if (filename == this.state.selectedFile)
        return (<ListGroup.Item variant="primary" onClick={e=>this.itemClicked(e)}> {filename} </ListGroup.Item>)
     else 
        return (<ListGroup.Item onClick={e=>this.itemClicked(e)}> {filename} </ListGroup.Item>)
  }
 
  render() {
    return (
       <div style={{cursor: this.state['cursor']}}>
       	<center>
          <h1> 
           Settings
          </h1> <br/>
              <div style={{width:'30%'}}>
                 <ListGroup>
                    {this.state.fileData['files'].map(fname=>this.getListItem(fname))}
                    {this.state.fileData['files'].length > 0 && <input type="button" value="Choose Selected File" onClick={e=>this.submitClicked()}/>}
                 </ListGroup>
                 <div>{this.state.cursor == 'progress' && (<img height='80px' width='120px' src={loading}/>)}</div>
              </div>
	    </center>
       </div>
    );
  }
}


export default Settings;

