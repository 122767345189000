import './App.css';
import Login from './components/Login';
import UserHeader from './components/UserHeader';
import Classifier from './components/Classifier';
import TrendsView from './components/TrendsView';
import DelaysView from './components/DelaysView';
import RepeatFailuresView from './components/RepeatFailuresView';
import Settings from './components/Settings';
import React from 'react';
import PollIcon from '@mui/icons-material/Poll';
import FlightIcon from '@mui/icons-material/Flight';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import SettingsIcon from '@mui/icons-material/Settings';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import logo from './AIXI2.png';

export const SidebarData = [
  { 
    "title":"Fleet Trends",
    "icon" : <PollIcon/>,
  },
  { 
    "title":"Aircraft Trends",
    "icon" : <FlightIcon/>,
  },

  { 
    "title":"Repeat Failures",
    "icon" : <SyncProblemIcon/>,
  },
  { 
    "title":"Classify Note",
    "icon" : <SpeakerNotesIcon/>,
  },
  {
    "title":"Delays",
    "icon": <AccessTimeIcon/>

  },
  {
    "title":"Settings",
    "icon" : <SettingsIcon/>,
  },

]
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {errorMsg:'',currentView:"Fleet Trends",user:{'name':null}};
    this.loginSuccess = this.loginSuccess.bind(this);
    this.logout = this.logout.bind(this);
  }

  loginSuccess(userData) {
    if(userData)
       this.setState({user:userData})
  }

  logout(errorMsg="") {
    this.setState({errorMsg:errorMsg,user:{'name': null}})
    console.log('logout',this.state)
  }



  componentDidMount() {
  }

  componentWillUnmount() {
  }

  textChanged = (value) =>{
  }

  render() {
    return (
    <div> 
      {!this.state.user['name'] && (<Login onSuccess={this.loginSuccess} errorMsg={this.state.errorMsg} logout={this.logout}/>)}
      { this.state.user['name'] && (
       <div className="App">
         <div className="Sidebar">
	     <span class="version"> v1.02 </span>
         <div style={{paddingTop:'20px',paddingBottom:'20px'}}> 
	    <center> <img src={logo} width={160} height={50} className="App-logo" alt="logo"/> </center> 
	 </div>
	 <ul className="SidebarList">
	 {SidebarData.map((val,key)=> {
	      return (
                 <li
		    key={key} 
		    id={this.state['currentView'] == val.title ? "active" : ""}
		    className="row" 
		    onClick={()=>{this.setState({currentView:val.title})}}
		  >
		  <div id="icon"> {val.icon} </div> <div id="title"> {val.title} </div>
		  </li>
	      )
	   })}
	 </ul>
	 </div>
      <div className="Content">
       <UserHeader user={this.state.user} logout={this.logout}/> 
       <center>
        <br/>
	    <img src={logo} width={300} height={91} className="App-logo" alt="logo"/>  
       <br/><br/>
       {this.state.currentView == "Fleet Trends" && (<TrendsView logout={this.logout} user={this.state.user} title_order = {['corrective_action_ata','object_code','failure_mode','action_code','acn']} title="Fleetwide Trends" />)}
       {this.state.currentView == "Aircraft Trends" && (<TrendsView logout={this.logout} user={this.state.user} title_order = {['acn','corrective_action_ata','object_code','failure_mode','action_code']} title="Single Aircraft Trends"/>)} 

       {this.state.currentView == "Repeat Failures" && (<RepeatFailuresView logout={this.logout} user={this.state.user}/>)}
       {this.state.currentView == "Classify Note" && (<Classifier user={this.state.user} logout={this.logout}/>)}
       {this.state.currentView == "Settings" && (<Settings user={this.state.user} logout={this.logout}/>)}
       {this.state.currentView == "Delays" && (<DelaysView logout={this.logout} user={this.state.user} />)}

        </center>
       </div>
      </div> 
    )}
      </div>
    )
}

}

export default App;
